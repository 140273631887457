import api from "../constants/api";

export const getUsers = async (token) => {
    try {
        const response = await api.get(`/auth/users`, {token: token});
        return response.data;
        console.log("Get Users : ",response.data);
    } catch (error) {
        throw error;
    }
};

export const updateUserBlockStatus = async (userId, token) => {
    try {
        const response = await api.put(`/auth/users/${userId}/block-status`, {token: token}
           // {
           // headers: {
          //      Authorization: `Bearer ${auth.token}`,
           // },
        );
    
        const updatedStatus = response.data.blockStatus;
        return updatedStatus;
        console.log("updated status : ",updatedStatus);


    } catch (error) {
        console.error("Error updating block status:", error);
    }
};

