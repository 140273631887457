import React, {useState} from 'react';
import DashboardLogo from '../../assets/AdminDashboard/DashboardLogo.png'; // Import your Dashboard logo
import AdminDropdownAccountIcon from '../../assets/AdminDashboard/Admin-dropdown-account-icon.svg';
import SocialMediaIcon from '../../assets/AdminDashboard/admin-dashboard-dropdown-socialmedia-icon.svg';
import BillingIcon from '../../assets/AdminDashboard/admin-dashboard-dropdown-billingicon.svg';
import LogoutIcon from '../../assets/AdminDashboard/admin-dashboard-dropdown-logouticon.svg';
import Avatar from '../../assets/AdminDashboard/avatar.png'; // Import user's avatar
import ArrowPointer from '../../assets/AdminDashboard/arrow-pointer.svg';
import {clearSelectedSideBarOption, clearToken} from "../../store";
import { useCookies } from "react-cookie";
import {useDispatch} from "react-redux"; // Import arrow-pointer svg

const Header = () => {
  const [, setCookie, removeCookie] = useCookies(['token']);
  const [isOpen, setIsOpen] = useState(false);
  const [userName] = useState('Amelia Charlotte');

  const dispatch = useDispatch();

  // Toggle dropdown
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="bg-[#161616] h-20 flex justify-between items-center px-4 md:px-6 ">
      {/* Left Side: Logo */}
      <div className="flex items-center">
        <img src={DashboardLogo} alt="Dashboard Logo" className="w-36 h-8 md:w-55 md:h-10"/>
      </div>

      {/* Right Side: User Profile & Dropdown */}
      <div className="flex items-center">
        {/* Dropdown with user info */}
        
      </div>

      {/* Right Side: Logout Button */}
      <div className="flex items-center">
        <button
          onClick={() => {dispatch(clearToken()); removeCookie("token"); dispatch(clearSelectedSideBarOption({Option: {}}))}}
          className="bg-gradient-to-r from-[#720B0B] to-[#C21F3A] hover:bg-none hover:bg-[#720B0B] text-white px-4 py-1 rounded-full flex items-center gap-2"
        >
          <span className='flex flex-row justify-center items-center'>      
            <img src={LogoutIcon} alt="Logout Icon" className="flex justify-center items-center w-6 h-4" />
            <span className='text-[0.8rem]'>Logout</span>
          </span>
        </button>
      </div>
    </header>
  );
};

export default Header;