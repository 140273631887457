import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// this custom hook is used for redirect the user if they have previously visted path of the website still saved in memory 
const useStartupRedirect = () => {
    const config = useSelector(state => state.config);
    const navigate = useNavigate();

    const startupRedirect = () => {        
        if(config.selectedSideBarOption?.path){
            navigate(config.selectedSideBarOption?.path);
        }
    }
    return [startupRedirect];
}

export default useStartupRedirect;