import { createSlice } from "@reduxjs/toolkit";

const configSlice = createSlice({
    name: "config",
    initialState: {
        selectedSideBarOption: {},
    },
    reducers: {
        setSelectedSideBarOption: (state, action) => {
            state.selectedSideBarOption = action.payload.option;
        },
        clearSelectedSideBarOption: (state) => {
            state.selectedSideBarOption = {};
        },
    },
});

export const { setSelectedSideBarOption, clearSelectedSideBarOption } = configSlice.actions;
export default configSlice.reducer;
