import React, {useEffect} from "react";
import Sidebar from "../../components/AdminDashboard/Sidebar";
import Footer from "../../components/AdminDashboard/Footer"; // Import Footer component
import Header from "../../components/AdminDashboard/Header";
import UploadPopup from "../../components/AdminDashboard/UploadPopup"; // Import UploadPopup component
import { useSelector } from "react-redux";
import useStartupRedirect from "../../hooks/useRedirects";

export const UploadClients = () => {
  
    const [startupRedirect] = useStartupRedirect();

    useEffect(() => {
      startupRedirect();
    }, [])

    return (
        <div style={styles.dashboard}>
            <Header />
            <div className="flex flex-col sm:flex-row w-100 overflow-hidden">
                <Sidebar />
                <div
                    style={styles.uploadPopupContainer}
                    className="h-screen sm:h-[100%]"
                >
                    <UploadPopup type="clients" />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export const UploadProductions = () => {

    const [startupRedirect] = useStartupRedirect();

    useEffect(() => {
      startupRedirect();
    }, [])

    return (
        <div style={styles.dashboard}>
            <Header />
            <div className="flex flex-col sm:flex-row w-100 overflow-hidden">
                <Sidebar />
                <div
                    style={styles.uploadPopupContainer}
                    className="h-screen sm:h-[100%]"
                >
                    <UploadPopup type="productions" />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export const UploadLyrics = () => {

    const [startupRedirect] = useStartupRedirect();

    useEffect(() => {
      startupRedirect();
    }, [])

    return (
        <div style={styles.dashboard}>
            <Header />
            <div className="flex flex-col sm:flex-row w-100 overflow-hidden">
                <Sidebar />
                <div
                    style={styles.uploadPopupContainer}
                    className="h-screen sm:h-[100%]"
                >
                    <UploadPopup type="lyrics" />
                </div>
            </div>
            <Footer />
        </div>
    );
};

const styles = {
    dashboard: {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        background: "radial-gradient(circle, #3E0000, #1F0000, #090909)",
        position: "relative",
    },
    content: {
        flex: 1,
        backgroundColor: "transparent",
    },
    uploadPopupContainer: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        justifyContent: "center",
        alignItems: "center",
        overflow: "auto",
    },
};
