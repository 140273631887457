import React, {useEffect} from 'react';
import Sidebar from "../../components/AdminDashboard/Sidebar";
import Footer from "../../components/AdminDashboard/Footer";  // Import Footer component
import Header from '../../components/AdminDashboard/Header';
import useStartupRedirect from '../../hooks/useRedirects';
import { persistor } from '../../store';

const AdminDashboard = () => {
  const [startupRedirect] = useStartupRedirect();

  useEffect(() => {
    startupRedirect();
  }, [])
  
  return (
    <div style={styles.dashboard}>
      <Header />
      <div className='flex flex-col sm:flex-row w-100 overflow-hidden'>
        <Sidebar />
        <div style={styles.uploadPopupContainer} className='h-screen sm:h-[100%]'>
        </div>
      </div>
      <Footer />
  </div>
  );
};

const styles = {
  dashboard: {
    display: 'flex',
    flexDirection: 'column', 
    height: '100vh',    
    background: 'radial-gradient(circle, #3E0000, #1F0000, #090909)',
    position: 'relative',  
  },
  content: {
    flex: 1,
    backgroundColor: 'transparent', 
  },
};

export default AdminDashboard;


