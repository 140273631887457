import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Sidebar from "../../components/AdminDashboard/Sidebar";
import Footer from "../../components/AdminDashboard/Footer";  // Import Footer component
import Header from '../../components/AdminDashboard/Header';
import CustomButton from "../../components/AdminDashboard/CustomButton";
import useStartupRedirect from "../../hooks/useRedirects";
import { getUsers } from "../../services/userService";
import { updateUserBlockStatus } from "../../services/userService";
import * as XLSX from 'xlsx';
import showToast from "../../utils/toastNotifications";

const Users = () => {
    const auth = useSelector(state => state.auth);
    const [loading, setLoading] = useState(false);
    const [startupRedirect] = useStartupRedirect();
    const [AllUsers, setAllUsers] = useState([]);
    const [userStatus, setUserStatus] = useState(
        AllUsers.reduce((status, user) => {
          status[user._id] = user.blockStatus || false;
          return status;
        }, {})
      );
    
    useEffect(() => {
        startupRedirect();
    }, [userStatus]);

    const downloadUserFile = async() => {
        try{
            setLoading(true);
            const result = await getUsers(auth.token);

            if(result.data.length > 0){
                // Extract unique keys
                const uniqueKeys = Array.from(new Set((result.data).flatMap((item) => Object.keys(item))));

                // Create rows with values aligned to unique keys
                const worksheetData = [

                    uniqueKeys, // First row: headers (unique keys)

                    ...(result.data).map((item) =>
                        uniqueKeys.map((key) => {
                            if(item[key] && item[key].toString().length > 0){
                                if(typeof item[key] === "object"){ // if the value is an object
                                    return Object.keys(item[key]).map(innerKey => item[key][innerKey]).join(", ");
                                }else if(Array.isArray(item[key])){ // if the value is an array
                                    return item[key].join(", ");
                                }else{
                                    return item[key];
                                }
                            }else{
                                return typeof item[key] === 'boolean' ? item[key] : '';
                            }// Fill missing values with empty strings
                        })
                    ),
                ];
                
                // Create a worksheet and workbook
                const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'users');

                // Export the workbook as an Excel file
                XLSX.writeFile(workbook, 'users.xlsx');
                setLoading(false);
            }else{
                showToast("warning", "No Users Found.");
                setLoading(false);
            }
        }catch(e){
            console.log(e);
            showToast("error", "Error Occurred During Generating The File.");
            setLoading(false);
        }
    }
    
    const fetchAllUsers = async () => {
        try{
            setLoading(true);
            const result = await getUsers(auth.token);

            setAllUsers(result.data); 
            console.log("users list : ",result.data);
            setLoading(false); 
        }
        catch(error){
            console.log(error);
            showToast("error", "Error Occurred during fetching users.");
            setLoading(false);
        }


    }

    useEffect(() => {
        fetchAllUsers();
      }, [userStatus]);

    const toggleBlockStatus = async(userId) => {
        try {
            const updatedStatus = !userStatus[userId];
            
            await updateUserBlockStatus(userId, updatedStatus);
            setUserStatus((prevStatus) => ({
                ...prevStatus,
                [userId]: updatedStatus,
            }));
    
            setAllUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user._id === userId ? { ...user, blockStatus: updatedStatus } : user
                )
            );
    
            showToast("success", `User has been ${updatedStatus ? "blocked" : "unblocked"}.`);
        } catch (error) {
            console.log("Error updating block status:", error);
            showToast("error", "Failed to update user status.");
        }
    
    };




    

    return (
        <>
            <div style={styles.dashboard}>
                <Header />
                <div className="flex flex-col sm:flex-row w-100 overflow-hidden">
                    <Sidebar />
                    <div
                        style={styles.uploadPopupContainer}
                        className="h-screen sm:h-[100%]"
                    >
                        <div className="inset-0 flex w-[100%] mx-5 bg-transparent sm:bg-transparent sm:bg-opacity-40 py-5">
                            <div
                                className="relative bg-[#1D1D1D] bg-opacity-55 rounded-lg px-5 2xl:px-7 py-3 2xl:py-7 w-[100%] shadow-md backdrop-blur-md 2xl:space-y-10"
                                style={{
                                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                                }}
                            >
                                <div className="flex justify-between items-center w-full px-4 py-2">
                                    <h1 className="text-white font-jost text-[1rem] 2xl:text-[1.4rem] text-left font-semibold space-x-10">
                                        Users
                                    </h1>
                                    {/* 
                                    </div><div className="flex w-[75%] h-20 bg-orange-400 justify-end">
                                    */}
                                    <div className="flex justify-end">
                                        <CustomButton label={"Download Users"} loading={loading} onClick={downloadUserFile}/>
                                    </div>
                                </div>
                                <div className="text-white pb-12">
                                    <table className="table-auto w-full text-white border-collapse">
                                        <thead>
                                            <tr className="bg-[#242424] text-center">
                                            <th className="px-4 py-2 border border-gray-700">First Name</th>
                                            <th className="px-4 py-2 border border-gray-700">Last Name</th>
                                            <th className="px-4 py-2 border border-gray-700">Email</th>
                                            <th className="px-4 py-2 border border-gray-700">Phone</th>
                                            <th className="px-4 py-2 border border-gray-700">Role</th>
                                            <th className="px-4 py-2 border border-gray-700">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {AllUsers.map((user) => (
                                            <tr key={user._id} className="bg-[#242424] text-center">
                                                <td className="px-4 py-2 border border-gray-700">{user.firstName}</td>
                                                <td className="px-4 py-2 border border-gray-700">{user.lastName}</td>
                                                <td className="px-4 py-2 border border-gray-700">{user.email}</td>
                                                <td className="px-4 py-2 border border-gray-700">{user.phone}</td>
                                                <td className="px-4 py-2 border border-gray-700">{user.role}</td>
                                                <td className="px-4 py-2 border border-gray-700 flex justify-center items-center">
                                                    <CustomButton
                                                        label={user.blockStatus ? 'Unblock User' : 'Block User'}
                                                        loading={loading}
                                                        onClick={() => toggleBlockStatus(user._id)}
                                                        className={user.blockStatus ? 'bg-green-500 hover:bg-green-700' : 'bg-red-500 hover:bg-red-700'}
                                                    />
                                                </td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};
const styles = {
    dashboard: {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        background: "radial-gradient(circle, #3E0000, #1F0000, #090909)",
        position: "relative",
    },
    content: {
        flex: 1,
        backgroundColor: "transparent",
    },
    uploadPopupContainer: {
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        // justifyContent: "center",
        // alignItems: "center",
        overflow: "auto",
    },
};

export default Users;