import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { useCookies } from "react-cookie";
import DOMPurify from 'dompurify';
import { twMerge } from 'tailwind-merge';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import Wrong from "../../assets/Vector.png";
import showToast from "../../utils/toastNotifications";
import { signIn } from "../../services/authService";
import { isEmail, isNotEmpty } from "../../utils/utils";
import { setToken } from "../../store";
import CustomButton from "../../components/AdminDashboard/CustomButton";

const AdminLogin = () => {

    const dispatch =  useDispatch();    
    const [, setCookie] = useCookies(['token']);

    const [credentials, setCredentials] = useState({
        email: "", password: ""
    });
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPAssword] = useState(false);

    const performSignIn = async() => {
        if(isNotEmpty(credentials.email) && isNotEmpty(credentials.password)){
            if(isEmail(credentials.email)){
                setLoading(true);
                signIn(credentials)
                .then(res => {                    
                    if(res.status === 200 && !(res.data?.user?.role.toLowerCase().localeCompare("admin"))){
                        setCookie('token', "Active user session", { path: '/', expires: new Date(Date.now() + 3600e3) });
                        dispatch(setToken({token: res.data.accessToken}));
                        showToast("success", "Signed in Successfully!");
                    }else{
                        showToast("error", "Only Admin Users Are Accessible");
                    }
                })
                .catch(err => {
                    console.log(err);
                })
                .finally(() => {setLoading(false)});
            }else{
                showToast("error", "Enter an Valid Email")
            }
        }else{
            showToast("error", "Fill Email and The Password");
        }
    }

    const submitLoginOnPressEnter = (e) => {
        if(e.key === "Enter"){
            performSignIn();
        }        
    }

    return (
        <>
            <div className="main-container flex justify-center items-center   ">
                <div className="relative w-screen  bg-studio bg-cover bg-center h-screen"></div>
                <div className="absolute w-[300px] sm:w-[485px]  bg-red-300 rounded-[22px] bg-radial shadow-custom flex justify-center items-center pb-[5%]">
                    <div className="absolute top-[16px] left-[264px] sm:left-[449px]">
                        {/* <img
                            src={Wrong}
                            className="w-[15px] h-[15px] sm:w-[20px] sm:h-[20px]"
                            alt="wrong"
                        ></img> */}
                    </div>
                    <div className="flex items-center flex-col">
                        <p className="text-white  mt-[26px] font-['Jost'] font-bold text-[22px] tracking-[5px] sm:text-[44px] sm:tracking-[10px]">
                            LOGIN
                        </p>
                        <div className="w-[230px]  mt-[13px] sm:w-[414px] ">
                            <div className="text-[12px] sm:text-[22px] font-['Jost'] text-[#FFFFFF]">
                                Email
                            </div>
                            <div>
                                {" "}
                                <input
                                    type="text"
                                    className="w-full h-[22px] sm:h-[44px] bg-transparent border-[1px] border-[#705F5F] rounded-[2px] sm:rounded-[5px] mt-[3px] pl-[5px] sm:pl-[10px] text-[#ffffff] text-[10px] sm:text-[20px] "
                                    value={credentials.email}
                                    onChange={(e) => {setCredentials({...credentials, email: DOMPurify.sanitize(e.target.value)})}}
                                    onKeyDown={(e) => submitLoginOnPressEnter(e)}
                                ></input>{" "}
                            </div>

                            <div className="flex flex-row text-[12px] sm:text-[22px] font-sans text-[#FFFFFF] mt-[18px]">
                                Password
                            </div>
                            <div className=" relative">
                                <input
                                    type={showPassword ? "text" : 'password'}
                                    value={credentials.password}
                                    onChange={(e) => {setCredentials({...credentials, password: DOMPurify.sanitize(e.target.value)})}}
                                    onKeyDown={(e) => submitLoginOnPressEnter(e)}
                                    className="w-full h-[22px] sm:h-[44px] bg-transparent border-[1px] border-[#705F5F] rounded-[2px] sm:rounded-[5px] mt-[3px] pl-[5px] sm:pl-[10px] text-[#ffffff] text-[10px] sm:text-[20px]"
                                />
                                {showPassword ? (
                                    <FaRegEyeSlash
                                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-white cursor-pointer"
                                        onClick={() => setShowPAssword(false)}
                                    />
                                    ) : (
                                    <FaRegEye
                                        className="absolute right-3 top-1/2 transform -translate-y-1/2 text-white cursor-pointer"
                                        onClick={() => setShowPAssword(true)}
                                    />
                                )}
                            </div>
                              
                            <div className="mt-5 text-[#FD0202] sm:text-[16px] text-[10px] font-normal font-['Jost'] ">
                                {/*
                                <a href="#">Forgot Password?</a>
                                 */}
                            </div>
                           
                        </div>
                        <CustomButton onClick={performSignIn} loading={loading} label={"Sign In"}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminLogin;