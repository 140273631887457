import { twMerge } from "tailwind-merge";
import Spinner from "../../assets/spinner.gif"

const CustomButton = (props) => {
    return (
        <button
            className={twMerge(
                "w-[60%] rounded-[2px] sm:rounded-[5px] sm:w-[200px] sm:h-[50px] px-[2px] py-2 sm:py-0 sm:px-[4px] my-5 flex justify-center items-center font-['Jost'] font-semibold text-[0.7rem] sm:text-[1rem] 2xl:text-[1.2rem] text-[#ffffff] hover:bg-none",
                props.loading || props.disabled
                    ? "bg-slate-700 opacity-45"
                    : "cursor-pointer bg-gradient-to-r from-[#720B0B] to-[#C21F3A] hover:bg-[#720B0B] "
            )}
            onClick={props.onClick}
            disabled={props.loading || props.disabled}
        >
            {props.label} {props.loading ? <img src={Spinner} className="w-8 h-8 ml-0 sm:ml-2" /> : ''}
        </button>
    );
};

export default CustomButton;
