import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice from "./slices/authSlice";
import configSlice from "./slices/configSlice";

const persistConfig_auth = {
    key: "auth",
    storage: storage,
    version: 1,
};

const persistConfig_config= {
    key: "config",
    storage: storage,
    version: 1,
};

const persistedReducer = persistReducer(persistConfig_auth, authSlice);
const persistedConfigReducer = persistReducer(persistConfig_config, configSlice);

export const store = configureStore({
    reducer: {
        auth: persistedReducer,
        config: persistedConfigReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);
export * from "./slices/authSlice";
export * from "./slices/configSlice";
