import React, { useState, useRef, useEffect } from "react";
import { twMerge } from 'tailwind-merge';
import UploadIcon from "../../assets/AdminDashboard/admin-dashboard-uploadfilesicon.svg"; // Import SVG file for upload button
import CloseIcon from "../../assets/AdminDashboard/Admin-closeIcon.svg"; // Import SVG file for close button
import showToast from "../../utils/toastNotifications";
import { uploadClientsFile, uploadLyricsFile, uploadProductionFile } from "../../services/uploadFileService";
import { useSelector } from "react-redux";
import { capitalize } from "../../utils/utils";
import CustomButton from "./CustomButton";

const UploadPopup = (props) => {
    const auth = useSelector(state => state.auth);
    const [isOpen, setIsOpen] = useState(false);
    const fileInputRef = useRef(null); // Reference for the file input
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleUploadClick = () => {
        fileInputRef.current.click(); // Trigger the file input click
    };

    const uploadFileHandler = () => {
      if(file && 
        (file.type === "application/vnd.ms-excel" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || file.type === "text/csv") &&
        (file.name.endsWith('.xls') || file.name.endsWith('.xlsx') || file.name.endsWith('.csv'))
      ){
        setLoading(true);
        const formData = new FormData();
        formData.append('file', file);

        if(props.type === "clients"){
          uploadClientsFile(formData, auth.token)
          .then((res) => {
            if(res.status === 201){
              showToast("success", "FIle uploaded Successfully!.")
            }else{
              showToast("error", "Something Went Wrong.")
            }
          })
          .catch(err => console.log(err))
          .finally(() => {
            setFile(null); 
            setLoading(false);
            setIsOpen(false);
            const field = document.getElementById("file-input-field");
            field.value = "";
          });
        }
        else if(props.type === "productions"){
          uploadProductionFile(formData, auth.token)
          .then((res) => {
            if(res.status === 201){
              showToast("success", "FIle uploaded Successfully!.")
            }else{
              showToast("error", "Something Went Wrong.")
            }
          })
          .catch(err => console.log(err))
          .finally(() => {
            setFile(null); 
            setLoading(false);
            setIsOpen(false);
            const field = document.getElementById("file-input-field");
            field.value = "";
          });
        }
        else if(props.type === "lyrics"){
          uploadLyricsFile(formData, auth.token)
          .then((res) => {
            if(res.status === 201){
              showToast("success", "FIle uploaded Successfully!.")
            }else{
              showToast("error", "Something Went Wrong.")
            }
          })
          .catch(err => console.log(err))
          .finally(() => {
            setFile(null); 
            setLoading(false);
            setIsOpen(false);
            const field = document.getElementById("file-input-field");
            field.value = "";
          });
        }
      }else{
        showToast("warning", "Please Select an Excel/CSV File to Upload");
      }
    }

    useEffect(() => {
      if(file?.type === "text/csv"){
        setIsOpen(true);
      }
    }, [file])
        
    return (
        <div className="inset-0 flex items-center justify-center w-[80%] max-w-[760px] h-[90%] 2xl:h-[80%] ch545:pt-[190px] bg-transparent sm:bg-transparent sm:bg-opacity-40 py-5">
            <div
                className="relative bg-[#1D1D1D] bg-opacity-55 rounded-lg px-5 2xl:px-7 py-3 2xl:py-7 w-[100%] sm:w-[80%]  shadow-md backdrop-blur-md 2xl:space-y-10"
                style={{
                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                }}
            >
                {/* Title */}
                <h1 className="text-white font-jost text-[1rem] 2xl:text-[1.4rem] text-left font-semibold space-x-10">
                    {props.type ? "Upload " + capitalize(props.type) + " Information" : "Upload Files"}
                </h1>

                {/* Inner Box */}
                <div className="bg-[#161616] shadow-[0px_0px_10px_#301212] rounded-md mt-3 px-5 2xl:px-7 py-3 2xl:py-7 flex flex-col w-[100%] mx-auto space-x-8 relative ">
                    {/* Inner Box Title */}
                    <h2 className="text-white font-jost text-[1rem] 2xl:text-[1.4rem] font-semibold mb-3 text-left">
                        Upload file
                    </h2>

                    {/* Upload Box */}
                    <div className="border-2 border-dashed border-[#6D6D6D] rounded-lg flex flex-col py-5 items-center justify-center text-center relative " style={{marginLeft: "0px"}}>
                        {/* Upload SVG Button */}
                        <img
                            src={UploadIcon}
                            alt="Upload Icon"
                            // className="w-[100px] h-[94px] mb-4 cursor-pointer"
                            className="w-[100px] h-[50px] mb-4 cursor-pointer"
                            onClick={handleUploadClick} // Trigger file input on click
                        />

                        {/* Hidden File Input */}
                        <input
                            type="file"
                            id="file-input-field"
                            name="file-input-field"
                            ref={fileInputRef}
                            style={{ display: "none" }} // Hide the input
                            accept=".xls,.xlsx,.csv" // Specify accepted file types
                            onChange={(e) => {                                
                                const inputFile = e.target.files[0];
                                if (inputFile) {
                                    setFile(inputFile);
                                }
                            }}
                        />

                        {/* Drop Here Text */}
                        <p className="font-poppins text-[16px] p-1 text-[#6D6D6D]">
                            Drop your image here, or{" "}
                            <span
                                className="text-[#C21F3A] cursor-pointer"
                                onClick={handleUploadClick}
                            >
                                browse
                            </span>
                        </p>

                        {/* File Types Text */}
                        <p className="font-poppins text-[12px] text-[#6D6D6D] mt-2">
                            Supports: XLS, XLSX, CSV : Excel files
                        </p>
                        <div className="w-[90%]">
                          <p className="text-white break-all text-[0.7rem] sm:text-[1rem]">{file && file.name}</p>
                        </div>
                    </div>

                    {/* Centered Upload Button */}
                    <div className="flex justify-center mx-auto" style={{marginLeft: "0px"}}>
                        <CustomButton onClick={uploadFileHandler} loading={loading} disabled={!file ? true : false} label={"Upload"}/>
                    </div>
                </div>
            </div>
            {isOpen && <div className="absolute w-[95%] left-0 right-0 top-0 mt-6 m-auto border border-yellow-300 rounded-md p-5 bg-[#240103]">
              <div className="w-full bg-orange-400">
                <div
                    className="absolute top-4 right-4 cursor-pointer"
                    onClick={() => {setIsOpen(!isOpen)}}
                >
                    <img
                        src={CloseIcon}
                        alt="Close Icon"
                        className="w-6 h-6"
                    />
                </div>
              </div>
              <p className="text-gray-300 text-[0.8rem] w-[95%]">
                Be carefull If You are Trying to Upload a CSV Files, Please Double Check to Ensure the Column Values of The File Doesn't Contain any Unnecessary commas Because of, Commas Inside column Values May Create Inconsist Values.
                If You Need To Place Commas Inside Column Values Remeber to Wrap Up The Entire Column Values Of The CSV File With Double Quotes ("")
              </p>
            </div>}
        </div>
    );
};

export default UploadPopup;