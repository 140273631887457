import React, { useState } from 'react'; 
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { twMerge } from 'tailwind-merge';
import { setSelectedSideBarOption } from '../../store';

const Sidebar = () => {
  const config = useSelector(state => state.config);
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize useNavigate

  const handleClick = (item) => {    
    dispatch(setSelectedSideBarOption({option: item}));
    navigate(item.path); // Navigate to the item's path
  };

  const items = [
    { id: 1, name: 'Home', path: '/admin-dashboard' }, // Add path for navigation
    { id: 5, name: 'Users', path: '/users'},
    { id: 2, name: 'Upload Clients', path: '/upload-clients' },
    { id: 3, name: 'Upload Productions', path: '/upload-productions' },
    { id: 4, name: 'Upload Lyrics', path: '/upload-lyrics' },
  ];

  // console.log(activeItem);
  
  return (
    <div className="bg-[#242424] w-full sm:w-[200px] 2xl:w-[250px] sm:h-[100vh] flex flex-row sm:flex-col overflow-auto">
      {items.map((item) => (
        <div
          key={item.id}
          onClick={() => handleClick(item)}
          className={twMerge(`flex items-center nav-item p-4 sm:p-5 md:p-6 text-white  text-[0.5rem] sm:text-[1rem] 2xl:text-[1rem] cursor-pointer transition-colors duration-300 whitespace-nowrap`, config.selectedSideBarOption?.id === item.id ? 'bg-[#340101]' : 'bg-[#242424]')}
        >
          {item.name}
        </div>
      ))}
    </div>
  );
};

// Add hover effect with CSS using Tailwind
const css = `
  .nav-item:hover {
    background-color: #000000;
  }
`;

// Append the CSS dynamically to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = css;
document.head.appendChild(styleSheet);

export default Sidebar;
